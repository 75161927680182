<template>
<div>
    <Privacy />
</div>
</template>

<script>
import Privacy from '../../components/dashboard/helpSupport/PrivacyPolicy.vue'
export default {
    components: {
        Privacy
    }
}
</script>

<style>
    