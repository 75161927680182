<template>
<div>
    <Wrapper>
        <Sidebar slot="sidebar" />
        <Navbar slot="navbar" v-if="showNavbar" />

        <div slot="content" :class="{'neg-margin': !showNavbar}">
            <div class="container">
                <div class="div-container mb-2 pt-4">
                    <div class="info-block">
                        <div class="num-block">
                            <div class="num-case fw-600">
                                <h3>1</h3>
                            </div>
                        </div>
                        <div class="detail-block">
                            <h4>{{tran.background}}</h4>
                            <p>{{tran.background_detail}}</p>
                        </div>
                        <div class="logo-block">
                            <img src="../../../assets/images/req.png" height="30">
                    </div>
                        </div>

                    </div>
               
                        <div class="div-container mb-2">
                            <div class="info-block">
                                <div class="num-block">
                                    <div class="num-case fw-600">
                                        <h3>2</h3>
                                    </div>
                                </div>
                                <div class="detail-block">

                                    <h4>{{tran.collecting}}</h4>
                                    <h6 class="pb-2 pt-2">

                                        {{tran.personal_info}}
                                    </h6>
                                    <p>{{tran.personal_info_detail}}</p>

                                    <hr style="width:30%">

                                    <h6 class="pb-2 pt-2">

                                        {{tran.how_we_use}}
                                    </h6>
                                    <p class="pb-2">{{tran.how_we_use_detail}}</p>
                                    <p class="pb-2">{{tran.how_we_use_detail_a}}</p>
                                    <p class="pb-2">{{tran.how_we_use_detail_b}}</p>
                                    <p class="pb-2">{{tran.how_we_use_detail_c}}</p>
                                    <p class="pb-2">{{tran.how_we_use_detail_d}}</p>
                                    <p class="pb-2">{{tran.how_we_use_detail_e}}</p>
                                    <p class="pb-2">{{tran.how_we_use_detail_f}}</p>
                                
                                    <hr style="width:30%">

                                    <h6 class="pb-2 pt-2">

                                        {{tran.how_personal}}
                                    </h6>

                                    <p class="pb-2">{{tran.how_personal_detail}}</p>
                                    <p class="pb-2">{{tran.how_personal_detail_a}}</p>
                                    <p class="pb-2">{{tran.how_personal_detail_b}}</p>
                                    <p class="pb-2">{{tran.how_personal_detail_c}}</p>

                                </div>
                                <div class="logo-block">
                                    <img src="../../../assets/images/req.png" height="30">
                                </div>
                                </div>

                            </div>
                            <div class="div-container mb-2">
                                <div class="info-block">
                                    <div class="num-block">
                                        <div class="num-case fw-600">
                                            <h3>3</h3>
                                        </div>
                                    </div>
                                    <div class="detail-block">
                                        <h4>{{tran.other_info}}</h4>
                                        <p class="pt-2">{{tran.other_info_detail}}</p>
                                  
                                        <h6 class="pb-2 pt-2">

                                            {{tran.security}}
                                        </h6>
                                        <p class="pb-2">{{tran.to_protect}}</p>
                                        <hr style="width:30%">
                                        <h6 class="pb-2 pt-2">

                                            {{tran.making_sure}}
                                        </h6>
                                        <p class="pb-2">{{tran.making_sure_detail}}</p>
                                        <hr style="width:30%">
                                        <h6 class="pb-2 pt-2">

                                            {{tran.protecting_children}}
                                        </h6>
                                        <p class="pb-2">{{tran.protecting_children_detail}}</p>
                                        <hr style="width:30%">
                                        <h6 class="pb-2 pt-2">

                                            {{tran.protecting_individual}}
                                        </h6>
                                        <p class="pb-2">{{tran.protecting_individual_detail}}</p>
                                        <hr style="width:30%">
                                        <h6 class="pb-2 pt-2">

                                            {{tran.update_privacy}}
                                        </h6>
                                        <p class="pb-2">{{tran.update_privacy_detail}}</p>
                                    </div>
                                    <div class="logo-block">
                                        <img src="../../../assets/images/req.png" height="30">
                    </div>
                                    </div>

                                </div>
                              
                                  
                                        </div>
                                    </div>
    </Wrapper>
</div>
</template>

<script>
import Navbar from '../../partials/Navbar'
import Sidebar from '../../partials/Sidebar'
import Footer from '../../partials/Footer'
import Wrapper from '../../partials/Wrapper'
import trans from "../../../../translations";
import searchProduct from "../SearchProduct";
import http from "../../../http/index";
import {
    commonJs
} from '../mixins/commonJs.js';

import {
    mapState
} from "vuex";

export default {
    mixins: [commonJs],
    data() {

        return {
            showNavbar:true
        }
    },

    watch: {

    },
    methods: {

    },
    components: {
        Navbar,
        Footer,
        Sidebar,
        Wrapper,
        searchProduct
    },

    computed: {
        ...mapState({

            accountOverview: state => state.market.accountOverview,

        }),

    },
    mounted() {
        let heading = {

            main: this.tran.privacy_policy,
        }
        this.$store.commit('market/savePageHeading', heading)
        this.tran = trans;
        if (this.$route.query.src == 'mobile') {
this.showNavbar = false
    }
    },
}
</script>

<style scoped>

</style>
